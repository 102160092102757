let ENV;
let therapyUrl;
let otherTherapyUrl;
let serverUrl;
let otherServerUrl;
let marketingUrl;
let otherMarketingUrl;
let ctry;
let amplitudeApiKey;
let mailchimpUrl;
let assessUrl;

const hostname =
  typeof window !== 'undefined' &&
  window &&
  window.location &&
  window.location.hostname;

if (hostname === 'localhost') {
  ENV = 'DEVELOPMENT';
  ctry = 'CA';
  therapyUrl = 'http://localhost:10101';
  otherTherapyUrl = 'http://localhost:10101';
  serverUrl = 'http://localhost:3000';
  otherServerUrl = 'http://localhost:3000';
  marketingUrl = 'http://localhost:8000';
  assessUrl = 'http://localhost:7070';
  otherMarketingUrl = 'http://localhost:8000';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
} else if (
  hostname === 'market.inkblottherapy.com' ||
  hostname === 'staging-marketing.inkblottherapy.com'
) {
  ENV = 'STAGING';
  ctry = 'CA';
  therapyUrl = 'https://staging.inkblottherapy.com';
  otherTherapyUrl = 'https://staging.inkblottherapy.com';
  serverUrl = 'https://api.inkblottherapy.com';
  otherServerUrl = 'https://api.inkblottherapy.com';
  marketingUrl = 'https://staging-marketing.inkblottherapy.com';
  assessUrl = 'https://staging.inkblotassess.com';
  otherMarketingUrl = 'https://marketing.inkblottherapy.net';
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
} else if (
  hostname === 'ink.inkblottherapy.com' ||
  hostname === 'inkblottherapy.com'
) {
  ENV = 'PRODUCTION';
  ctry = 'CA';
  therapyUrl = 'https://app.inkblottherapy.com';
  otherTherapyUrl = 'https://portal.inkblottherapy.com';
  serverUrl = 'https://api.inkblotpractice.com';
  otherServerUrl = 'https://medapi.inkblottherapy.com';
  marketingUrl = 'https://inkblottherapy.com';
  assessUrl = 'http://inkblotassess.com';
  otherMarketingUrl = 'https://us.inkblottherapy.com';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
} else if (hostname === 'marketing.inkblottherapy.com') {
  ENV = 'UAT';
  ctry = 'CA';
  therapyUrl = 'https://uat.inkblottherapy.com';
  otherTherapyUrl = 'https://uat.inkblottherapy.com';
  serverUrl = 'https://web.inkblottherapy.com';
  otherServerUrl = 'https://web.inkblottherapy.com';
  assessUrl = 'http://example.com';
  marketingUrl = 'https://marketing.inkblottherapy.com';
  otherMarketingUrl = 'https://market.inkblottherapy.com';
  amplitudeApiKey = null;
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
} else if (hostname === 'us.inkblottherapy.com') {
  ENV = 'US PROD';
  ctry = 'US';
  therapyUrl = 'https://portal.inkblottherapy.com';
  otherTherapyUrl = 'https://app.inkblottherapy.com';
  serverUrl = 'https://medapi.inkblottherapy.com';
  otherServerUrl = 'https://api.inkblotpractice.com';
  marketingUrl = 'https://us.inkblottherapy.com';
  assessUrl = 'http://us.inkblotassess.com';
  otherMarketingUrl = 'https://inkblottherapy.com';
  amplitudeApiKey = null;
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
} else if (hostname === 'us-staging-marketing.inkblottherapy.com') {
  ENV = 'US DEV';
  ctry = 'US';
  therapyUrl = 'https://us-staging.inkblottherapy.com';
  otherTherapyUrl = 'https://us-staging.inkblottherapy.com';
  serverUrl = 'https://usapi.inkblottherapy.com';
  otherServerUrl = 'https://usapi.inkblottherapy.com';
  marketingUrl = 'https://us-staging-marketing.inkblottherapy.com';
  assessUrl = 'http://us-staging.inkblotassess.com';
  otherMarketingUrl = 'https://us-staging-marketing.inkblottherapy.com';
  amplitudeApiKey = null;
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
} else if (hostname === 'demo-marketing.inkblottherapy.com') {
  ENV = 'DEMO';
  ctry = 'CA';
  therapyUrl = 'https://demo.inkblottherapy.com';
  otherTherapyUrl = 'https://demo.inkblottherapy.com';
  serverUrl = 'https://demoapi.inkblottherapy.com';
  otherServerUrl = 'https://demoapi.inkblottherapy.com';
  assessUrl = 'http://demo.inkblotassess.com';
  marketingUrl = 'https://demo-marketing.inkblottherapy.com';
  otherMarketingUrl = 'https://demo-marketing.inkblottherapy.com';
  amplitudeApiKey = null;
  mailchimpUrl =
    'https://inkblotpractice.us14.list-manage.com/subscribe/post?u=4d30430e084407ca95222ca95&amp;id=d8623b62d3';
}

export const COUNTRY = `${ctry}`;
export const ENVIRONMENT = `${ENV}`;
export const THERAPY_URL = `${therapyUrl}`;
export const OTHER_THERAPY_URL = `${otherTherapyUrl}`;
export const SERVER_URL = `${serverUrl}`;
export const OTHER_SERVER_URL = `${otherServerUrl}`;
export const MARKETING_URL = `${marketingUrl}`;
export const ASSESS_URL = `${assessUrl}`;
export const OTHER_MARKETING_URL = `${otherMarketingUrl}`;
export const AMPLITUDE_API_KEY = `${amplitudeApiKey}`;
export const MAILCHIMP_URL = `${mailchimpUrl}`;
